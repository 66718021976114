export type SectionType = 'model' | 'scan' | 'it' | 'integration' | 'inventory' | 'digitization' | 'bim';

export enum SectionTypeEnum {
  DIGITIZATION = 'digitization',
  INVENTORY = 'inventory',
  BIM = 'bim',
  INTEGRATION = 'integration',
  IT = 'it',
  SCAN = 'scan',
  MODEL = 'model',
}