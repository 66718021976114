import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DatocmsService } from '../../services/datocms.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Category, Project } from './model';
import { ProjectDialogComponent } from './modal.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MediaQueryService } from '../../services/media-query.service';

@Component({
  standalone: true,
  selector: 'app-experience-section',
  imports: [
    TranslateModule,
    CommonModule,
    SlickCarouselModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule
  ],
  templateUrl: 'experience.component.html',
  styleUrls: ['experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExperienceComponent implements OnInit {
  public realizations$: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);
  public categories$: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
  public filteredRealizations$: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);
  public categories: Category[] = [];
  public filteredRealizations: Project[] = [];
  public selectedCategoryId: string | null = null;

  public slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: true,
    dots: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          centerMode: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          centerMode: false,
        }
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
        }
      }
    ]
  };

  public isLoading = signal<boolean>(false);

  public categoryForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private datocmsService: DatocmsService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    public mediaQueryService: MediaQueryService,
  ) {
    this.categoryForm = this.fb.group({
      selectedCategory: [null]
    });
  }

  ngOnInit(): void {
    this.translateService.use(this.translateService.currentLang).subscribe(() => {
      this.loadArticles();
    });

    this.categoryForm.get('selectedCategory')?.valueChanges.subscribe((categoryId: string) => {
      this.onCategoryChange(categoryId);
    });

    this.categories$.subscribe(categories => {
      this.categories = categories;
    });

    this.filteredRealizations$.subscribe(filteredRealizations => {
      filteredRealizations.sort((a, b) => Number(a.index) - Number(b.index));
      this.filteredRealizations = filteredRealizations;
    });
  }

  public filterProjects() {
    this.isLoading.set(true);
    this.changeDetectorRef.detectChanges();

    if (this.selectedCategoryId) {
      const filteredProjects = this.realizations$.getValue().filter(project =>
        project.category.some(category => category.id === this.selectedCategoryId)
      );
      this.filteredRealizations$.next(filteredProjects);
    } else {
      this.filteredRealizations$.next(this.realizations$.getValue());
    }

    this.isLoading.set(false);
    this.changeDetectorRef.detectChanges();
  }

  onCategoryChange(categoryId: string) {
    this.selectedCategoryId = categoryId;
    this.filterProjects();
  }

  openProjectDialog(project: Project) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'custom-backdrop';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = project;

    this.dialog.open(ProjectDialogComponent, dialogConfig);
  }

  private loadArticles(): void {
    const currentLang = this.translateService.currentLang || 'en';

    this.isLoading.set(true);
    this.datocmsService.getAllRealizations(currentLang).subscribe((result: any) => {

      let realizations;

      switch (currentLang) {
        case 'pl':
          realizations = result.data.allRealizationenpls;
          break;
        case 'de':
          realizations = result.data.allRealizationdes;
          break;
        default:
          realizations = result.data.allRealizationens;
      }

      if (realizations) {
        this.realizations$.next(realizations);

        const uniqueCategories = Array.from(new Set<string>(realizations
          .map((item: Project) => item.category)
          .flat()
          .map((category: Category) => JSON.stringify(category))))
          .map((category: string) => JSON.parse(category) as Category);

        this.categories$.next(uniqueCategories);

        this.mediaQueryService.isMobile$.subscribe((isMobile) => {
          if (!isMobile) {
            if (uniqueCategories.length > 0) {
              this.selectedCategoryId = uniqueCategories[0].id;
              this.categoryForm.get('selectedCategory')?.setValue(this.selectedCategoryId, { emitEvent: false });
              this.filterProjects();
            }
          } else {
            this.getAllRealizations();
          }
        });
      }
      this.isLoading.set(false);
      this.changeDetectorRef.detectChanges();
    });
  }

  private getAllRealizations() {
    this.filteredRealizations = this.realizations$.getValue();
  }
}
