import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ExperienceComponent } from './sections/experience/experience.component';
import { AboutComponent } from './sections/about/about.component';
import { ContactComponent } from './sections/contact/contact.component';
import { FooterComponent } from './sections/footer/footer.component';
import { BlogComponent } from './sections/blog/blog.component';
import { MainComponent } from './sections/main/main.component';

@Component({
  standalone: true,
  selector: 'app-basic-view',
  imports: [
    TranslateModule,
    ExperienceComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    BlogComponent,
    MainComponent,
  ],
  templateUrl: 'basic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BasicComponent {
}
