import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DatocmsService } from '../basic/services/datocms.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from '../basic/sections/contact/contact.component';
import { FooterComponent } from '../basic/sections/footer/footer.component';
import { MarkdownModule } from 'ngx-markdown';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MediaQueryService } from '../basic/services/media-query.service';


@Component({
  standalone: true,
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    ContactComponent,
    FooterComponent,
    MarkdownModule
  ]
})
export class ArticleDetailComponent implements OnInit {
  public mediaQueryService = inject(MediaQueryService);
  public route = inject(ActivatedRoute);
  public datocmsService = inject(DatocmsService);
  public router = inject(Router);
  public article: any;
  public prevArticleId: string | null = null;
  public nextArticleId: string | null = null;
  private translateService = inject(TranslateService);
  private sanitizer = inject(DomSanitizer);

  public ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const articleId = params.get('id');
      const currentLang = this.translateService.currentLang || 'en';

      if (articleId) {
        this.loadArticle(articleId, currentLang);
        this.loadArticlesAndSetNavigation(articleId, currentLang);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }

  renderContent(children: any[]): string {
    return children.map((child: any) => {
      const classNames = this.getMarks(child.marks);

      if (child.type === 'span') {
        return child.marks && child.marks.includes('strong') ? `<strong>${child.value}</strong>` : child.value;
      } else if (child.type === 'list') {
        return this.renderList(child);
      } else if (child.type === 'paragraph') {
        return `<p class="${classNames}">${this.renderContent(child.children)}</p>`;
      } else if (child.type === 'heading') {
        const tag = `h${child.level}`;
        return `<${tag} class="${classNames}">${this.renderContent(child.children)}</${tag}>`;
      }
      return '';
    }).join('');
  }

  renderList(list: any): string {
    if (list.style === 'bulleted') {
      return `<ul>${list.children.map((item: any) => `<li>${this.renderContent(item.children)}</li>`).join('')}</ul>`;
    } else if (list.style === 'ordered') {
      return `<ol>${list.children.map((item: any) => `<li>${this.renderContent(item.children)}</li>`).join('')}</ol>`;
    }
    return '';
  }

  getMarks(marks: string[]): string {
    if (!marks) {
      return '';
    }
    return marks.map(mark => {
      switch (mark) {
        case 'strong':
          return 'font-weight-bold';
        case 'heading':
          return 'font-heading';
        case 'em':
          return 'font-italic';
        case 'underline':
          return 'text-underline';
        case 'code':
          return 'font-monospace';
        default:
          return '';
      }
    }).join(' ');
  }

  getSanitizedUrl(url: string = ''): SafeResourceUrl | string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url) || url;
  }

  private loadArticle(id: string, lang: string): void {
    this.datocmsService.getArticleById(id, lang).subscribe({
      next: (data) => {
        let result;

        switch (lang) {
          case 'pl':
            result = data.data.allArticlepls;
            break;
          case 'de':
            result = data.data.allArticledes;
            break;
          default:
            result = data.data.allArticleens;
        }

        if (result && result.length > 0) {
          this.article = result[0];
        } else {
          this.router.navigate(['/article', this.nextArticleId]);
        }
      },
      error: (err) => {
        console.error('Error loading article:', err);
      }
    });
  }

  private loadArticlesAndSetNavigation(currentArticleAdresUrl: string, lang: string): void {
    this.datocmsService.getAllArticles(lang).subscribe({
      next: (data) => {
        const articles = lang === 'pl' ? data.data.allArticlepls : data.data.allArticleens;
        const currentIndex = articles.findIndex((article: any) => article.adresUrl === currentArticleAdresUrl);

        if (articles.length > 0) {
          this.prevArticleId = articles[(currentIndex - 1 + articles.length) % articles.length]?.adresUrl;

          this.nextArticleId = articles[(currentIndex + 1) % articles.length]?.adresUrl;
        }
      },
      error: (err) => {
        console.error('Error loading articles for navigation:', err);
      }
    });
  }
}