import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { EmailService } from '../../services/email.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MediaQueryService } from '../../services/media-query.service';

@Component({
  standalone: true,
  selector: 'app-contact-section',
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.scss'],
  providers: [
    EmailService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent implements OnInit {
  public mediaQueryService =  inject(MediaQueryService);
  private formBuilder = inject(FormBuilder);
  private emailService =  inject(EmailService);

  public contactForm!: FormGroup;
  public emailSend: boolean;
  public emailError: boolean;

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      countryCode: ['PL', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{9,12}$')]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      consent: [false, Validators.requiredTrue]
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.emailSend = false;
      this.emailError = false;
      this.emailService.sendEmail(this.contactForm.value).subscribe(
        response => {
          this.emailSend = true;
        },
        error => {
          console.error('error')
          this.emailError = true;
        }
      );
    }
  }
}
